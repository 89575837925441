@keyframes Floatingy {
    from {
        -webkit-transform: translate(0, 0px);
    }
    65% {
        -webkit-transform: translate(50px, 0);
    }
    to {
        -webkit-transform: translate(0, 0px);
    }
}

@keyframes Floatingx {
    from {
        -webkit-transform: translate(50px, 0px);
    }
    65% {
        -webkit-transform: translate(0, 0);
    }
    to {
        -webkit-transform: translate(50px, 0px);
    }
}

.sky-image {
    background: #83c1d8;
}

.presentation-page .rellax-text-container .h1-seo, .index-page .rellax-text-container .h1-seo {
    background: #eaf3ff;
    background: -moz-linear-gradient(top, #FFFFFF 35%, #75a6c3 100%);
    background: -webkit-linear-gradient(top, #FFFFFF 35%, #75a6c3 100%);
    background: linear-gradient(to bottom, #FFFFFF 35%, #75a6c3 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

[data-background-color="dark-blue"], .index-page [data-background-color="dark-blue"] {
    background: rgb(3 20 37 / 80%);
}

[data-background-color="dark"] {
    background: #2c2c2c;
}

.bgt-white {
    background: rgba(255, 255, 255, 0.8);
}

.darker {
    filter: brightness(50%) blur(2px);
}

.svg-logo-height {
    height: 40px;
    width: auto;
    margin-bottom: 5px;
}

.cookie {
    z-index: 10;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    line-height: 60px;
    background: #303030;
    color: #fff;
}

.btn-blue {
    background: #386efa !important;
}

.btn-blue:hover {
    background: #3e72fd !important;
}

a {
    color: #386efa;
}

a:hover {
    color: #3e72fd;
}

.btn-zyonic-blue {
    background: #5ba2ed !important;
}

.btn-zyonic-blue:hover {
    background: #66a8f6 !important;
}

.btn-zyonic-red {
    background: #ef6057 !important;
}

.btn-zyonic-red:hover {
    background: #f86a60 !important;
}

.bg-blue {
    background: #386efa !important;
}

.bg-blue:hover {
    background: #3e72fd !important;
}

.logo {
    width: 30px;
}

.navbar-transparent .logo {
    visibility: hidden;
}

.form-check .form-check-sign::after {
    color: #fff;
}