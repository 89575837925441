body, .bg-white, .section:not([data-background-color="dark-blue"]), .collapse.show #ceva, .collapse.show {
    background-color: rgb(4, 24, 44) !important;
}

[data-background-color="dark-blue"] {
    background: linear-gradient(rgba(2, 16, 30, 0.8) 80%, rgb(4, 24, 44) 100%);
}

[data-background-color="black"] {
    background-color: rgb(3, 19, 35) !important;
}

h1, h2, h3, h4, h5, h6 {
    color: #fff !important;
}

span, p, li, .text-dark {
    color: #eaeaea !important;
}

a.text-dark:focus, a.text-dark:hover {
    color: #f3f3f3 !important;
}

.page-header-city, .page-header-image {
    filter: brightness(70%);
}

.sky-image {
    background-color: #557486 !important;
}